import { ThemeProvider } from '@mui/material'
import { createThemeWithMode } from '@sicarx/sxw-core'
import { Suspense, lazy, useEffect, useMemo } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { DrawerM, SicarLoading } from '@sicarx/sxw-components';
import { IndexRoutes } from './routes'
import { useLocalStore } from '@sicarx/sxw-api';
import { UpdatePaymentCard } from './components/UpdatePaymentCard';

const Feedback = lazy(() => import("@sicarx/sxw-components").then(({ Feedback }) => ({ default: Feedback })));
const UpdatePermissionsAlert = lazy(() => import("./components/UpdatePermissionsAlert").then(({ UpdatePermissionsAlert }) => ({ default: UpdatePermissionsAlert })));
const SubscriptionDialog = lazy(() => import("@sicarx/sxw-components").then(({ SubscriptionDialog }) => ({ default: SubscriptionDialog })));

function App() {
    let snackBarOffline: SnackbarKey;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isDarkMode = useLocalStore(state => state.darkMode.isDarksMode);
    const isDevelopment = import.meta.env.VITE_TESTING === 'TESTING';
    const mode = isDarkMode ? 'dark' : 'light';
    //Development
    const theme = useMemo(() => createThemeWithMode(mode, localStorage?.getItem("mainColor") ? false : isDevelopment), [mode, localStorage?.getItem("mainColor") ? false : isDevelopment]);

    //Production
    //const theme = useMemo(() => createThemeWithMode(mode), [mode]);

    useEffect(() => {
        //console.log("location", location)
        window.addEventListener("online", () => {
            enqueueSnackbar('Conectado', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
            closeSnackbar(snackBarOffline)
        });
        window.addEventListener("offline", () => {
            snackBarOffline = enqueueSnackbar('Desconectado', {
                variant: 'error',
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
        });

        return () => {
            window.removeEventListener("offline", () => {
                console.log("Estamos fuera de linea")
            });
            window.removeEventListener("online", () => {
                console.log("Estamos en linea")

            });
        };
    }, [])
    return (<>
        <ThemeProvider theme={theme}>
            {location.pathname === "/updatePaymentCard"
                ?
                <UpdatePaymentCard />
                :
                <>
                    {
                        location.pathname === "/didCompleteSubscription"
                            ? <Suspense>
                                <SicarLoading />
                                <IndexRoutes />
                            </Suspense>
                            :
                            <>
                                <DrawerM />
                                <Suspense>
                                    <SubscriptionDialog />
                                </Suspense>
                                <SicarLoading />
                                <Suspense>
                                    <Feedback />
                                </Suspense>
                                <Suspense>
                                    <IndexRoutes />
                                </Suspense>
                                <Suspense>
                                    <UpdatePermissionsAlert />
                                </Suspense>
                            </>
                    }
                </>
            }
        </ThemeProvider >
    </>)
}

export default App;