import { Navigate, useRoutes } from "react-router-dom";
import { CompaniesRoutes } from "@sicarx/sxw-company"
import { AccountRoutes } from "@sicarx/sxw-account"
import { SettingsRoutes } from "@sicarx/sxw-settings"
import { DocumentQueriesRoutes } from "@sicarx/sxw-document-queries"
import { DocumentRoutes } from "@sicarx/sxw-document"
import { ProductRoutes } from "@sicarx/sxw-product"
import { AgentRoutes } from "@sicarx/sxw-agent"
/* import { SubscriptionRoutes } from "@sicarx/sxw-subscription" */
import { SalesReportsRoutes } from "@sicarx/sxw-sales-reports"
import { PurchaseRoutes } from "@sicarx/sxw-purchase"
import { StatisticsRoutes } from "@sicarx/sxw-statistics"
import { PaymentComponent } from "../components/Payment";
import { ResolvePaymentComponent } from "../components/ResolvePaymentComponent";
import { UpdatePaymentCard } from "../components/UpdatePaymentCard";
//import { SpacesRoutes } from "@sicarx/sxw-spaces"

export const IndexRoutes = (() => {
    const IndexRoutes = useRoutes([
        ...AccountRoutes,
        ...CompaniesRoutes,
        ...SettingsRoutes,
        ...ProductRoutes,
        ...DocumentRoutes,
        ...DocumentQueriesRoutes,
        ...AgentRoutes,
        //...SpacesRoutes,
        ...PurchaseRoutes,
        //...SubscriptionRoutes,
        ...SalesReportsRoutes,
        ...StatisticsRoutes,
        /* {
            path: "/subscription_mobile",
            element: (location.search.split("=").length >= 2) ? <PaymentComponent /> : <Navigate replace to="/" />,
        }, */
        {
            path: "/updatePaymentCredit",
            element: <UpdatePaymentCard />,
        },
        {
            path: "/didCompleteSubscription",
            element: (location.search.split("=").length >= 2) ? <PaymentComponent /> : <ResolvePaymentComponent />,
        },
        { path: "*", element: <Navigate replace to="/" /> },
    ]);
    return IndexRoutes;
})